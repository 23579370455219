/*---------------------------------------------
    ## intro
---------------------------------------------*/
.single-intro {
	margin-bottom: 30px;
	.thumb {
		background: var(--main-color-one);
		display: inline-block;
		height: 70px;
		width: 70px;
		line-height: 85px;
		text-align: center;
		border-radius: 5px;
		margin-bottom: 25px;
		img {
			width: auto;
			display: inline-block;
		}
	}
	.text {
		font-size: 45px;
		font-family: var(--heading-font);
		font-weight: 700;
		color: var(--main-color-one);
	}
	.details {
		.title {
			margin-bottom: 15px;
			font-weight: 700;
		}
		p {
			margin-bottom: 0;
		}
		.read-more {
			font-weight: 600;
			@include transition(all, 0.5s, ease);
			padding-top: 12px;
			display: block;
			&:hover {
				color: var(--main-color-one);
				text-decoration: underline;
			}
		}
	}
	&.style-two {
		.thumb {
			border-radius: 50%;
			font-size: 26px;
			font-weight: 500;
			font-family: var(--heading-font);
			color: $color-white;
		}
	}
}
.single-intro-media {
	background: var(--main-color-one);
	padding: 40px;
	border-radius: 10px;
	@include transition(all, 0.6s, ease);
	margin-bottom: 30px;
	.media-body {
		padding-left: 30px;
		h4 {
			color: $color-white;
			margin-bottom: 11px;
			font-weight: 600;
		}
		p {
			color: $color-white;
			margin-bottom: 0;
		}
	}
	&.single-intro-media-active {
		background: #061B40;
	}
}




/*---------------------------------------------
    ## follow-dream
---------------------------------------------*/
.single-follow-dream {
	margin-bottom: 17px;
	.media-left	{
		height: 50px;
		width: 50px;
		border-radius: 4px;
		background: #FFEEE2;
		line-height: 50px;
		text-align: center;
		margin-right: 30px;
		margin-top: 6px;
		i {
			font-size: 16px;
			color: var(--main-color-one);
		}
	}
	.media-body {

	}
}



/*---------------------------------------------
    ## single cat
---------------------------------------------*/
.cat-single {
	border: 1px solid rgba(112, 112, 112, 0.20);
	display: block;
	padding: 50px 10px 40px 10px;
	margin-bottom: 30px;
	border-radius: 10px;
	@include transition(all, 0.5s, ease);
	img,i {
		font-size: 46px;
		color: var(--main-color-one);
		margin-bottom: 15px;
	}
	p {
		font-size: 20px;
		color: var(--heading-color);
		margin-bottom: 0;
	}
	&:hover {
	    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
	}
}


/*---------------------------------------------
    ## single-floor-list
---------------------------------------------*/
.single-floor-list {
	margin-bottom: 30px;
	.media-left	{
		background: #FFDEC5;
		border-radius: 5px;
		height: 40px;
		width: 40px;
		line-height: 40px;
		text-align: center;
		color: var(--main-color-one);
		margin-right: 15px;
	}
	.media-body {
		h6 {
			margin-bottom: 0;
			font-weight: 600;
		}
		p {
			margin-bottom: 0;
			font-size: 14px;
		}
	}
}