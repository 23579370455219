/* =============
   Otp Screen
============= */

.otp-input-design {
  justify-content: center;
  align-items: center;
  margin: 1.5em 0;
  text-align: center;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    width: 2.5em !important;
    padding: 0 !important;
    font-weight: bolder;
    &:focus {
      outline: none;
    }
  }
  span.separatorItem {
    width: 1em;
  }
  small {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 1em;
    a {
      color: #fda94f !important;
    }
  }
}