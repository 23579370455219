.agent-profile-section {

  .single-service {
    box-shadow: 0px 2px 12px #97a1b21a;
    background: #ededed;
    padding: 10px 10px;
    border-radius: 10px;
    transition: all 0.5s ease;
    margin: 10px;

  }

  .slick-slider {
    button::before {
      font-size: 30px;
    }
  }
}
