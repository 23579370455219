/*----------------------------------------------
    ## Accordion
----------------------------------------------*/
.single-accordion {
    border: 1px solid #F5F9FF;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: $box-shadow;
    padding: 25px 30px;
    .card-header {
        background: transparent;
        border-radius: 0;
        border: 0;
        padding: 0;
        h2 {
            button {
                font-size: 18px;
                color: var(--main-color-one);
                width: 100%;
                text-align: left;
                position: relative;
                background: transparent;
                border-radius: 0;
                border: 0;
                padding: 0;
                float: left;
                font-weight: 500;
        		padding-right: 20px;
        		cursor: pointer;
        		transition: all .3s ease;
                &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 12px;
                	background: var(--main-color-one);
                    top: 50%;
                    right: 0;
                    opacity: 1;
                }
                &:before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 12px;
                	background: var(--main-color-one);
                    top: 50%;
                    transition: all .3s ease;
                    right: 0;
                	transform: rotate(0);
                }
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                } 
                &.collapsed {
                	color: var(--heading-color);
                	&:before {
                    	transform: rotate(90deg);
                    	background: var(--heading-color);
	                }
	                &:after {
	                	background: var(--heading-color);
	                }
                }
            }
        }
    }
    .card-body {
    	padding: 0;
		margin-top: 20px;
    }
}