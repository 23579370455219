.agent-property-area{



  .share-property-modal {

    .broadcast-model-contact {
      height: 285px !important;
      overflow: hidden;
      overflow-y: auto;
    }

    .broadcast-model-contact .contact {
      position: relative;
      padding: 6px 0 6px 0;
      font-size: 0.9em;
      cursor: pointer;
      transition: 0.7s;
    }
    .broadcast-model-contact .contact .wrap {
      width: 88%;
      margin: 0 auto;
      position: relative;
    }

    .broadcast-model-contact .broad-chat-user-img img {
      width: 40px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    .broadcast-model-contact li
    {
      margin: 5px 2px;
    }
  }



  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #fd7e14;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #fd7e14;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .img-view{
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
  }
  .layout-section:hover{
  color: #fd7e14;
    font-size: 22px;

  }
  #imgPreviewModal .modal-dialog{
    max-width: 900px;
  }


}