
//== Template Main Color
$color-white : #fff;

//== social media colors
$facebook: #3b5999;
$twitter: #55acee;
$dribbble: #ea4c89;
$behance: #131418;
$google-plus: #dd4b39;
$linkedin: #0077B5;
$instagram: #e4405f;
$pinterest: #c8232c;


/*-----------------
    # Typography
-----------------*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Poppins:200,300,400,400i,500,600,700i&display=swap");

//  transition
$transition: all .3s ease-in;
$transition-long: all .5s ease-in;
$box-shadow: 0px 2px 12px #97A1B21A;
$box-shadow2: 0px 20px 40px rgba(0, 0, 0, 0.12);