.why-choose-us-area{

  @media (max-width: 580px) {

    padding-top: 20px !important;
    padding-bottom: 10px;

    .single-intro .details .title{
      font-size: 1em;
    }
    .single-intro .thumb{
      line-height: 86px;
    }
  }

}