.single-user-list {

  @media (max-width: 765px) {
    .details {
      display: flex !important;
      flex-direction: column !important;
    }
    .input-group {
      display: flex !important;
       flex-direction: column !important;
    }
  }
  @media (max-width: 398px) {
    .details span{
      font-size: 12px !important;
    }
  }
}
