.featured-area-section{



  .share-property-modal {

    .broadcast-model-contact {
      height: 285px !important;
      overflow: hidden;
      overflow-y: auto;
    }

    .broadcast-model-contact .contact {
      position: relative;
      padding: 6px 0 6px 0;
      font-size: 0.9em;
      cursor: pointer;
      transition: 0.7s;
    }
    .broadcast-model-contact .contact .wrap {
      width: 88%;
      margin: 0 auto;
      position: relative;
    }

    .broadcast-model-contact .broad-chat-user-img img {
      width: 40px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    .broadcast-model-contact li
    {
      margin: 5px 2px;
    }
  }
}