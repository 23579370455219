.property-list-container{



  .modal-content{
    margin-top: 75px;
  }

  .rld-single-input input{
    height: 38px !important;
  }
  .widget-sidebar-search .widget-sidebar-item-wrap{
    margin-bottom: 20px !important;
  }

  @media (min-width: 991px){
    .collapseResponsivec{
      display: block !important;
    }
  }
  @media (max-width: 991px)  {
    .advance-filter{
      display: none;
    }
    .sty{
      display: none!important;
    }
    .advance-filters{
      display: block !important;
    }
    //.widget-sidebar-search-wrap{
    //  margin: 1em;
    //}
  }
  @media (max-width: 556px) {
    .modal-content{
      margin-top: 73px !important;
    }
    //.widget-sidebar-search-wrap{
    //  margin: 1.5em !important;
    //}
    #area_unit_id{
      margin-top: 0px!important;
    }
  }

  .share-property-modal {

    .broadcast-model-contact {
      height: 285px !important;
      overflow: hidden;
      overflow-y: auto;
    }

    .broadcast-model-contact .contact {
      position: relative;
      padding: 6px 0 6px 0;
      font-size: 0.9em;
      cursor: pointer;
      transition: 0.7s;
    }
    .broadcast-model-contact .contact .wrap {
      width: 88%;
      margin: 0 auto;
      position: relative;
    }

    .broadcast-model-contact .broad-chat-user-img img {
      width: 40px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
    .broadcast-model-contact li
    {
      margin: 5px 2px;
    }
  }

}