.agentprofile-container {
  position: inherit;
  img {
    width: 15%;
    margin-bottom: 2em;
    border-radius: 65px;

  }
  i {
    position: absolute;
    font-size: 15px;
    top: 46%;
    right: 43%;
    background: white;
    box-shadow: 6px 9px 14px 7px #ededed;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
  }
  input[type ="file"] {
    display: none;
  }




}