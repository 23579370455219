/*----------------------------------------------
    ## main search
----------------------------------------------*/
.main-search-area {
	margin-top: -50px;
}
.rld-main-search {
	padding: 50px 30px 35px 30px;
	background: $color-white;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
	border-radius: 0 6px 6px 6px;
	.rld-single-input,
	.rld-single-select .single-select {
		margin-bottom: 15px;
	}
	.btn {
		width: 100%;
	}
}
.rld-single-input {
	position: relative;
	input {
		height: 48px;
		line-height: 48px;
		width: 100%;
		padding: 0 25px;
		border: 1px solid #D4D4D4;
		@include transition(all, 0.4s, ease);
		border-radius: 4px;
		font-size: 15px;
		&:hover, 
		&:active, 
		&:focus {
			border: 1px solid var(--main-color-one);
		}

	}

	&.left-icon {
		&:before {
			content: '';
			//background-image: url('../../img/icons/33.png');
		    position: absolute;
		    top: 17px;
		    background-size: auto;
	        height: 17px;
	    	width: 12px;
		    left: 19px;
		    background-repeat: no-repeat;
		}
		input {
			padding-left: 40px;
		}
	}
	&.left-icon-location {
		&:before {
			content: '';
			background-image: url('../../img/icons/1.png');
		    position: absolute;
		    top: 17px;
		    background-size: auto;
	        height: 17px;
	    	width: 12px;
		    left: 15px;
		    background-repeat: no-repeat;
		}
		input {
			padding-left: 40px;
		}
	}
	textarea {
		height: 120px;
		width: 100%;
		padding: 10px 20px;
		border: 1px solid #D4D4D4;
		@include transition(all, 0.4s, ease);
		border-radius: 4px;
		font-size: 15px;
		&:hover, 
		&:active, 
		&:focus {
			border: 1px solid var(--main-color-one);
		}

	}
      PhoneInput{
        padding: 0 40px;
      }
}
.rld-single-select {
	.single-select {
		height: 48px;
		line-height: 47px;
		width: 100%;
		padding: 0 34px 0 20px;
		border: 1px solid #D4D4D4;
		@include transition(all, 0.4s, ease);
		position: relative;
		border-radius: 4px;
		&:hover, 
		&:active, 
		&:focus {
			border: 1px solid var(--main-color-one);
		} 
		&:after {
			border: 0;
		}
		&:before {
			content: '';
			background-image: url('../../img/icons/2.png');
		    position: absolute;
		    top: 19px;
		    background-size: auto;
		    height: 10px;
		    width: 11px;
		    right: 14px;
		    left: auto;
		    background-repeat: no-repeat;
		}
		.list {
			width: 100%;
		}
	}
}

.rld-price-slider-wrap {
	.rld-price-slider,
	.rld-size-slider {
	    height: 5px;
		background: #FFF0E5;
	    border: 0;
        margin: 20px 0 0 0;
	}
	.ui-slider-handle {
		height: 24px;
		width: 42px;
		background: var(--main-color-one);
	    border: 0;
	    text-align: center;
	    color: $color-white;
	    border-radius: 30px;
        top: -10px;
        font-size: 13px;
        line-height: 25px;
	}
}


/****** banner-search *******/
.rld-banner-search {
	.rld-single-input {
		input {
		    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
		    border: 0;
	        padding-right: 140px;
		}
		button {
		    position: absolute;
		    right: 0;
		    top: 0;
		    height: 48px;
		    line-height: 48px;
		    border: 0;
		    padding: 0 20px;
		    font-weight: 600;
		    background: var(--main-color-one);
		    color: $color-white;
		    cursor: pointer;
		    border-radius: 0 4px 4px 0;
		}
	}
}


@media screen and (max-width: 736px)   {
	.rld-single-input {
		position: relative;
		input {
			height: 48px;
			line-height: 48px;
			width: 100%;
			padding: 0 25px;
			border: 1px solid #D4D4D4;
			@include transition(all, 0.4s, ease);
			border-radius: 4px;
			//font-size: 9px !important;
			&:hover,
			&:active,
			&:focus {
				border: 1px solid var(--main-color-one);
			}

		}

	}

}
@media screen and (max-width: 568px)   {
	.rld-single-input {
		position: relative;
		input {
			height: 48px;
			line-height: 48px;
			width: 100%;
			padding: 0 25px;
			border: 1px solid #D4D4D4;
			@include transition(all, 0.4s, ease);
			border-radius: 4px;
			//font-size: 8px !important;
			&:hover,
			&:active,
			&:focus {
				border: 1px solid var(--main-color-one);
			}
		}
	.rdl-mob{
		font-size: 15px !important;
	}
	}

}
@media screen and (max-width: 650px)   {
	.rld-single-input {
		position: relative;

		.rdl-mobb{
			font-size: 12px !important;
		}
		#rdl-mobb{
			font-size: 12px !important;
		}
		#rdl-mobbb{
			font-size: 15px !important;
		}
		#password-mobb{
			font-size: 15px !important;
		}
		#email-mobb{
			font-size: 15px !important;
		}
		#fname{
			font-size: 15px !important;
		}
		#lname{
			font-size: 15px !important;
		}
		#specialty{
			font-size: 15px !important;
		}
		#website{
			font-size: 15px !important;
		}
		#email{
			font-size: 15px !important;
		}
		#mobile{
			font-size: 15px !important;
		}
		#zip_code{
			font-size: 15px !important;
		}
		#company_name{
			font-size: 15px !important;
		}
		#company_street_address{
			font-size: 15px !important;
		}
		#team_name{
			font-size: 15px !important;
		}
		#company_state{
			font-size: 15px !important;
		}
		#company_zip_code{
			font-size: 15px !important;
		}
		#home_number{
			font-size: 15px !important;
		}
		#number_of_years_in_business{
			font-size: 15px !important;
		}
		#designations{
			font-size: 15px !important;
		}
		#languages_spoken{
			font-size: 15px !important;
		}
		#hobbies{
			font-size: 15px !important;
		}
		#cites_expert{
			font-size: 15px !important;
		}
		#subdivisions_expert{
			font-size: 15px !important;
		}
		.select-search__input{
			font-size: 15px !important;
		}
		#twitter_link{
			font-size: 15px !important;
		}
		#instagram_link{
			font-size: 15px !important;
		}
		#facebook_link{
			font-size: 15px !important;
		}
		#carpet_area{
			font-size: 15px !important;
		}
		#super_area{
			font-size: 15px !important;
		}
		#builder_name{
			font-size: 12px !important;
		}
		#list_date{
			font-size: 15px !important;
		}
		#street{
			font-size: 15px !important;
		}
		#direction{
			font-size: 15px !important;
		}
		#home_size{
			font-size: 15px !important;
		}
		#price{
			font-size: 15px !important;
		}
		#age_of_property{
			font-size: 13px !important;
		}
		#rera_id{
			font-size: 15px !important;
		}
		#floor_availability{
			font-size: 15px !important;
		}
		#units_on_floor{
			font-size: 15px !important;
		}
		#type_of_ownership{
			font-size: 15px !important;
		}
		#leed_certificate{
			font-size: 15px !important;
		}
		#disclaimer{
			font-size: 15px !important;
		}
		#owner_name{
			font-size: 12px !important;
		}
		#land_area{
			font-size: 15px !important;
		}
		#authority_approval{
			font-size: 15px !important;
		}
		#no_of_open_sides{
			font-size: 15px !important;
		}
		#landmarks{
			font-size: 15px !important;
		}
	}

}
@media screen and (max-width: 320px)   {
	.rld-single-input {
		position: relative;

		.rdl-mobb{
			font-size: 8px !important;
		}
		#rdl-mobb{
			font-size: 10px !important;
		}
	}


}