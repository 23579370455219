/* --------------------------------------------------
	##All filter 
---------------------------------------------------- */
.property-filter-menu {
	margin-bottom: 40px;
	button {
		background: #FFEEE2;
		color: var(--main-color-one);
		height: 52px;
		padding: 0 30px;
		line-height: 52px;
		border: 0;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;
		@include transition(all, 0.5s, ease);
		margin-right: 6px;
		border-radius: 5px;
		margin-bottom: 10px;
		&:hover,
		&:focus,
		&.active {
			background: var(--main-color-one);
			color: $color-white;
		}
	}
}