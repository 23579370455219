.private_chat {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: #32465a;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;

  .wrap p {
    color: #fff;
    font-size: 1.1em;
  }
  #contacts p {
    color: #fff;

  }
  .contact-profile p {
    font-weight: 600;
    font-size: 1.3em;
  }

  input {
    margin-left: 4px;
    border-radius: 24px;
    margin-bottom: 5px;

  }

  @media (max-width: 576px) {
    h5 {
      font-size: 15px;
    }
  }
  #createBroadcastModal {
    .search input:focus {
      border: none;
    }
    .contact + .active-chat {
      color: #d4d4d4 !important;
    }
    @media (max-width: 980px) {
      .modal-content {
        margin-top: 40px;
      }
    }
    @media (max-width: 765px) {
      .modal-content {
        margin-top: 70px;
      }
    }
    @media (max-width: 576px) {
      .modal-content {
        margin-top: 1px;
      }
    }
  }

  .broadcast-model-contact {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;

    li {
      margin: 5px 2px;
      @media (max-width: 300px) {
        margin: 0px 10px;
      }
    }
    p {
      color: #000 !important;
      margin-left: -13px;

    }

    .contact {
      position: relative;
      padding: 6px 0 6px 0;
      font-size: 0.9em;
      cursor: pointer;
      transition: 0.7s;

      .active-chat-b {
        color: #ffff !important;
        background: #1c293745 !important;
      }
      .wrap {
        width: 88%;
        margin: 0 auto;
        position: relative;

      }
    }

    .broad-chat-user-img {

      padding: 0 !important;
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .broad-chat-user-img img {

      width: 40px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }

  }
}

#frame {
  width: 95%;
  min-width: 360px;
  max-width: 1000px;
  height: 80vh;
  min-height: 300px;
  max-height: 720px;
  background: #E6EAEA;

  #sidepanel {
    float: left;
    min-width: 280px;
    max-width: 340px;
    width: 40%;
    height: 100%;
    background: #2c3e50;
    color: #f5f5f5;
    overflow: hidden;
    position: relative;
    #profile {
      width: 100%;
      margin: 25px 25px;
      .wrap {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        -moz-transition: 0.3s height ease;
        -o-transition: 0.3s height ease;
        -webkit-transition: 0.3s height ease;
        transition: 0.3s height ease;
        img {
          width: 50px;
          border-radius: 50%;
          padding: 3px;
          border: 2px solid #e74c3c;
          height: auto;
          float: left;
          cursor: pointer;
          -moz-transition: 0.3s border ease;
          -o-transition: 0.3s border ease;
          -webkit-transition: 0.3s border ease;
          transition: 0.3s border ease;
        }
        img.online {
          border: 2px solid #2ecc71;
        }
        img.away {
          border: 2px solid #f1c40f;
        }
        img.busy {
          border: 2px solid #e74c3c;
        }
        img.offline {
          border: 2px solid #95a5a6;
        }
        p {
          float: left;
          margin-left: 15px;
        }
        i.expand-button {
          float: right;
          margin-top: 23px;
          font-size: 0.8em;
          cursor: pointer !important;
          //color: #435f7a;
        }

        #status-options {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          width: 150px;
          margin: 70px 0 0 0;
          border-radius: 6px;
          z-index: 99;
          line-height: initial;
          background: #435f7a;
          -moz-transition: 0.3s all ease;
          -o-transition: 0.3s all ease;
          -webkit-transition: 0.3s all ease;
          transition: 0.3s all ease;
          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #435f7a;
            margin: -8px 0 0 24px;
          }
          ul {
            overflow: hidden;
            border-radius: 6px;
            li {
              padding: 15px 0 30px 18px;
              display: block;
              cursor: pointer;
              &:hover {
                background: #496886;
              }
              span.status-circle {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 5px 0 0 0;
                &:before {
                  content: '';
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  margin: -3px 0 0 -3px;
                  background: transparent;
                  border-radius: 50%;
                  z-index: 0;
                }
              }
              p {
                padding-left: 12px;
              }
            }
            li#status-online {
              span.status-circle {
                background: #2ecc71;
              }
            }
            li#status-online.active {
              span.status-circle {
                &:before {
                  border: 1px solid #2ecc71;
                }
              }
            }
            li#status-away {
              span.status-circle {
                background: #f1c40f;
              }
            }
            li#status-away.active {
              span.status-circle {
                &:before {
                  border: 1px solid #f1c40f;
                }
              }
            }
            li#status-busy {
              span.status-circle {
                background: #e74c3c;
              }
            }
            li#status-busy.active {
              span.status-circle {
                &:before {
                  border: 1px solid #e74c3c;
                }
              }
            }
            li#status-offline {
              span.status-circle {
                background: #95a5a6;
              }
            }
            li#status-offline.active {
              span.status-circle {
                &:before {
                  border: 1px solid #95a5a6;
                }
              }
            }
          }
        }
        #status-options.active {
          opacity: 1;
          visibility: visible;
          margin: 75px 0 0 0;
        }
        #expanded {
          padding: 100px 0 0 0;
          display: block;
          line-height: initial !important;
          label {
            float: left;
            clear: both;
            margin: 0 8px 5px 0;
            padding: 5px 0;
          }
          input {
            border: none !important;
            margin-bottom: 6px;
            background: #32465a;
            border-radius: 3px;
            color: #f5f5f5;
            padding: 7px;
            width: calc(100% - 43px);
            &:focus {
              outline: none;
              background: #435f7a;
            }
          }
        }
      }
      .logged-in-online {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img.online {
          border: 2px solid #2ecc71;
        }
        img.away {
          border: 2px solid #f1c40f;
        }
        img.busy {
          border: 2px solid #e74c3c;
        }
        img.offline {
          border: 2px solid #95a5a6;
        }
        img {
          width: 50px;
          border-radius: 50%;
          padding: 3px;
        }
        p {
          margin-bottom: 0 !important;
          text-align: left;
          color: white;
          width: 100%;
          padding: 0 5px;
        }
      }
    }
    #profile.expanded {
      .wrap {
        height: 210px;
        line-height: initial;
        p {
          margin-top: 20px;
        }
        i.expand-button {
          -moz-transform: scaleY(-1);
          -o-transform: scaleY(-1);
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
          filter: Flip;
          -ms-filter: "FlipH";
        }
      }
    }
    #search {
      border-top: 1px solid #32465a;
      border-bottom: 1px solid #32465a;
      font-weight: 300;
      label {
        position: absolute;
        margin: 10px 0 0 20px;
      }
      input {
        font-family: "proxima-nova", "Source Sans Pro", sans-serif;
        padding: 10px 0 10px 46px;
        width: calc(100% - 25px);
        border: none !important;
        background: #32465a;
        color: #f5f5f5;
        &:focus {
          outline: none;
          background: #435f7a;
        }
        &::-webkit-input-placeholder {
          color: #f5f5f5;
        }
        &::-moz-placeholder {
          color: #f5f5f5;
        }
        &:-ms-input-placeholder {
          color: #f5f5f5;
        }
        &:-moz-placeholder {
          color: #f5f5f5;
        }
      }
    }
    #contacts {
      height: calc(100% - 215px);
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
        background: #2c3e50;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #243140;
      }
      ul {
        li.contact {
          position: relative;
          padding: 10px 0 15px 0;
          font-size: 0.9em;
          cursor: pointer;
          border-bottom: 1px solid #838383;
          &:hover {
            background: #32465a;
          }
          .wrap {
            width: 88%;
            margin: 0 auto;
            position: relative;
            span {
              position: absolute;
              left: 0;
              margin: -2px 0 0 -2px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 2px solid #2c3e50;
              background: #95a5a6;
            }
            span.online {
              background: #2ecc71;
            }
            span.away {
              background: #f1c40f;
            }
            span.busy {
              background: #e74c3c;
            }
            img {
              width: 40px;
              border-radius: 50%;
              float: left;
              margin-right: 10px;
            }
            .meta {
              padding: 5px 0 0 0;
              .name {
                font-weight: 600;
              }
              .preview {
                margin: 5px 0 0 0;
                padding: 0 0 1px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -moz-transition: 1s all ease;
                -o-transition: 1s all ease;
                -webkit-transition: 1s all ease;
                transition: 1s all ease;
                span {
                  position: initial;
                  border-radius: initial;
                  background: none;
                  border: none;
                  padding: 0 2px 0 0;
                  margin: 0 0 0 1px;
                  opacity: .5;
                }
              }
            }
          }
        }
        li.contact.active {
          background: #32465a;
          border-right: 5px solid #435f7a;
          span.contact-status {
            border: 2px solid #32465a !important;
          }
        }
      }
    }
    #contacts.expanded {
      height: calc(100% - 334px);
    }
    #bottom-bar {
      position: absolute;
      width: 100%;
      bottom: 0;
      button {
        float: left;
        border: none;
        width: 50%;
        padding: 10px 0;
        background: #32465a;
        color: #f5f5f5;
        cursor: pointer;
        font-size: 0.85em;
        font-family: "proxima-nova", "Source Sans Pro", sans-serif;
        &:focus {
          outline: none;
        }
        &:nth-child(1) {
          border-right: 1px solid #2c3e50;
        }
        &:hover {
          background: #435f7a;
        }
        i {
          margin-right: 3px;
          font-size: 1em;
        }
      }
    }
  }
  .content {
    float: right;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .contact-profile {
      width: 100%;
      height: 62px;
      line-height: 60px;
      background: #f5f5f5;
      .dmenu {
        display: flex;
        flex-direction: column;
        line-height: 10px;
      }

      img {
        width: 40px;
        border-radius: 50%;
        float: left;
        margin: 9px 12px 0 9px;
      }
      p {
        float: left;
      }
      .expand-button-responsive {
        display: none;
      }
      .social-media {
        float: right;
        i {
          margin-left: 14px;
          cursor: pointer;

          &:nth-last-child(1) {
            margin-right: 20px;
          }
          &:hover {
            color: #435f7a;
          }
        }
      }
    }
    .messages {
      height: 80%;
      /*min-height: calc(100% - 93px);
      max-height: calc(100% - 93px);*/
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      &::-webkit-scrollbar {
        width: 8px;
        background: rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
      }
      ul {
        li {
          display: inline-block;
          clear: both;
          float: left;
          margin: 0 15px 5px 15px;
          width: calc(100% - 25px);
          font-size: 0.9em;
          &:nth-last-child(1) {
            margin-bottom: 20px;
          }
          img {
            width: 22px;
            border-radius: 50%;
            float: left;
          }
          .chat-logs {
            padding: 15px;
            height: 370px;
            overflow-y: scroll;
          }

          .chat-logs::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
          }

          .chat-logs::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
          }

          .chat-logs::-webkit-scrollbar-thumb {
            background-color: #5A5EB9;
          }

          p {
            display: inline-block;
            padding: 10px 15px;
            border-radius: 20px;
            max-width: 205px;
            line-height: 130%;
          }
        }
        li.sent {
          img {
            margin: 6px 8px 0 0;
          }
          p {
            background: #435f7a;
            color: #f5f5f5;
            a {
              color: skyblue !important;
            }
          }
        }
        li.replies {
          img {
            float: right;
            margin: 6px 0 0 8px;
          }
          p {
            background: #f5f5f5;
            float: right;
            a {
              color: skyblue !important;
            }
          }
        }
      }
    }
    .message-input {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 99;
      .wrap {
        position: relative;
        input {
          font-family: "proxima-nova", "Source Sans Pro", sans-serif;
          float: left;
          border: none;
          padding: 11px 32px 10px 8px;
          /*font-size: 0.8em;*/
          font-weight: 500;
          color: #32465a;
          border-radius: 24px;
          flex: 3;
          margin-right: 1em;
          &:focus {
            outline: none;

          }
        }
        .attachment {
          position: absolute;
          right: 60px;
          z-index: 4;
          margin-top: 10px;
          font-size: 1.1em;
          color: #435f7a;
          opacity: .5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        button {
          border: none;
          cursor: pointer;
          background: #435f7a;
          color: #f5f5f5;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: #435f7a;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.chat-user-img {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-recent-msg {
  padding: 0 !important;
  margin: 0 !important;
  text-align: left;
  .meta {
    padding: 0 !important;

    .broadcast-icon {
      position: absolute;
      top: 15px;
      right: 18px;
      font-size: 17px;

    }
    .name {
      padding: 0 !important;
      margin: 0 !important;
    }
    .preview {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

span.selected-person {
  border: 1px solid #ededed;
  border-radius: 1em;
  padding: .1em .5em;
}

.broadcast-next-btn {
  background: #fd7e1457;
  padding: .5em;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //color: #000;
}

//@media(max-width: 780px) {
//  .private_chat {
//
//    #frame > #sidepanel {
//      display: none !important;
//    }
//    #frame #sidepanel {
//      height: calc(90% - (1px * 0));
//    }
//
//    .opne-chat-btn {
//      display: block !important;
//      font-size: 40px;
//      position: absolute;
//      right: 0;
//    }
//    #mySidenav {
//      height: calc(100vh - (-17px + 2px)) !important;
//      min-height: 100px !important;
//      max-height: 793px !important;
//    }
//
//    .sidenav {
//      display: grid !important;
//      //top: 0;
//      right: 0;
//      position: absolute;
//      z-index: 999;
//      overflow-x: hidden;
//      transition: 0.5s;
//
//    }
//    .sidenav .closebtn {
//      position: absolute;
//      top: -15px;
//      right: 5px;
//      font-size: 36px;
//      margin-left: 50px;
//    }
//
//  }
//}
//
@media (max-width: 365px) and (max-width: 600px) {
  #frame {
    margin-top: 2em;
  }
  //.private_chat #frame #sidepanel {
  //  height: calc(83% - 1px) !important;
  //}
  .private_chat .sidenav {
    width: 78%;
  }
  #mySidenav {
    height: 120vh !important;
    min-height: 100px !important;
    max-height: 850px !important;
  }
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 10px 40px !important;
  }
  #frame .content .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 56px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    font-weight: 500;
    color: #32465a;
    border-radius: 24px;
  }
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
    max-width: 40px;
    margin-top: 7px;
  }
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 10px !important;
    margin-top: 5px;
  }

}

//
//@media (max-width: 363px) {
//  .private_chat #frame #sidepanel {
//    height: calc(75% - 1px) !important;
//  }
//}
//
//
//
@media screen and (max-width: 735px) {
  #frame {
    margin-top: 2em;
  }
  //.private_chat #frame #sidepanel {
  //  height: calc(83% - 1px) !important;
  //}
  .private_chat .sidenav {
    width: 78%;
  }
  #mySidenav {
    height: 100vh !important;
    min-height: 100px !important;
    max-height: 850px !important;
  }
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 10px 40px !important;
  }
  #frame .content .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 56px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    font-weight: 500;
    color: #32465a;
    border-radius: 24px;
  }
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
    max-width: 40px;
    margin-top: 7px;
  }
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 10px !important;
    margin-top: 5px;
  }
}

//
//@media screen and (min-width: 900px) {
//  #frame {
//    .content {
//      width: calc(100% - 340px);
//    }
//  }
//}
//
//.expand-button {
//  cursor: pointer !important;
//  color: #435f7a;
//}
//
//
//@media screen and (min-width: 735px) {
//  #frame {
//    .content {
//      .messages {
//        ul {
//          li {
//            p {
//              max-width: 300px;
//            }
//          }
//        }
//      }
//    }
//  }
//}

@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
  .modal-content {
    top: 50px !important;
  }
}

@media screen and (max-width: 735px) {
  #frame {

    #sidepanel {
      width: 58px;
      min-width: 58px;
      #profile {
        width: 100%;
        margin: 0 auto;
        padding: 5px 0 0 0;
        background: #32465a;
        .wrap {
          height: 55px;
          img {
            width: 40px;
            margin-left: 4px;
          }
          p {
            display: none;
          }
          i.expand-button {
            display: none;
          }

          #status-options {
            width: 58px;
            margin-top: 57px;
            &:before {
              margin-left: 23px;
            }
            ul {
              li {
                padding: 15px 0 35px 22px;
                span.status-circle {
                  width: 14px;
                  height: 14px;
                  &:before {
                    height: 18px;
                    width: 18px;
                  }
                }
                p {
                  display: none;
                }
              }
            }
          }
          #status-options.active {
            margin-top: 62px;
          }
        }
      }
      #search {
        display: none;
      }
      #contacts {
        height: calc(100% - 121px);
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        ul {
          li.contact {
            padding: 6px 0 46px 8px;
            .wrap {
              width: 100%;
              img {
                margin-right: 0px;
              }
              .meta {
                display: none;
              }
            }
          }
        }
      }
      #bottom-bar {
        button {
          float: none;
          width: 100%;
          padding: 15px 0;
          &:nth-child(1) {
            border-right: none;
            border-bottom: 1px solid #2c3e50;
          }
          i {
            font-size: 1.3em;
          }
          span {
            display: none;
          }
        }
      }
    }
    .content {
      width: calc(100% - 58px);
      min-width: 300px !important;
      .contact-profile {
        .expand-button-responsive {
          display: block;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }

      .messages {
        max-height: calc(100% - 0px);
      }
      .message-input {
        .wrap {
          input {
            padding: 15px 32px 16px 8px;
          }
          .attachment {
            margin-top: 17px;
            right: 65px;
          }
          button {
            padding: 16px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .broadcast-model-contact {
    height: 210px !important;
  }

}

@media (min-width: 576px) {
  .broadcast-model-contact {
    height: 285px !important;
  }
}

@media screen and (min-width: 900px) {
  #frame {
    .content {
      width: calc(100% - 340px);
    }
  }
  //.broadcast-model-contact{
  //  height: 600px!important;
  //}

}

@media screen and (min-width: 735px) {

  #frame {
    .content {
      .messages {
        ul {
          li {
            p {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
}





