.search-grid-component {
    .rld-single-input.left-icon:before {
        content: '';
        background-image: url("../../img/icons/33.png");
        position: absolute;
        top: 15px;
        background-size: auto;
        height: 17px;
        width: 12px;
        left: 15px;
        background-repeat: no-repeat; }
        .agent.rld-single-input.left-icon:before {
            content: '';
        position: absolute;
        top: 14px;
        background-size: auto;
        height: 17px;
        width: 17px;
        left: 16px;
        background-repeat: no-repeat; }
}