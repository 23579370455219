.register-container {
  select {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 46px;
    border: 1px solid #D4D4D4;
    @include transition(all, 0.4s, ease);
    border-radius: 4px;
    font-size: 15px;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--main-color-one);
    }
    .PhoneInput{
      display: block;
    }
  }
  .react-tel-input .form-control{
    //width: 290px !important;
    background: #F3F3F3;
  }

}