.call-to-action-area{

  @media screen and (max-width: 380px) {

      padding-top: 20px;
    .call-to-action .cta-content{
      padding: 10px 12px !important;
    }

    .call-to-action .cta-content .btn{
        margin-top: 1em;
      float: none!important;
    }
  }
  @media screen and (max-width: 280px) {
    .call-to-action .cta-content .title{
      font-size: 13px!important;
    }
  }
}