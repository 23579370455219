.user-list-area{


  @media (max-width: 580px) {
    .input-group{
      display: flex;flex-direction: column;
      align-items: center;
    }
    .rld-single-input.left-icon:before{
      top: 48px;
    }
    .responsive-btn{
      display: block !important;
    }
    .responsive-btn1{
      display: none ;
    }

  }
}