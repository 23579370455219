.sub-category-container {
  .slick-slider {
    .single-service {
      .thumb {
        img {
          width: auto;
          position: relative;
        }
      }
    }
    .slick-prev {
      position: absolute;
      top: 50%;
      left: -100px;
      height: 50px;
      width: 50px;
      border: 1px solid rgba(112, 112, 112, 0.17);
      text-align: center;
      line-height: 46px;
      border-radius: 50%;
      font-size: 28px;
      background: white;
      box-shadow: 0 2px 12px #97A1B21A;
      @include transition(all, 0.5s, ease);
      color: rgba(112, 112, 112, 0.17);
      &:before {
        display: none;
      }
      &:hover {
        border-color: #FFEEE2;
        box-shadow: 0px 2px 12px rgba(255, 233, 209, 0.75);
        color: var(--main-color-one);
      }
    }
    .slick-next {
      position: absolute;
      top: 50%;
      right: -100px;
      height: 50px;
      width: 50px;
      border: 1px solid rgba(112, 112, 112, 0.17);
      text-align: center;
      line-height: 46px;
      border-radius: 50%;
      font-size: 28px;
      padding-left: 5px;
      background: white;
      box-shadow: 0 2px 12px #97A1B21A;
      @include transition(all, 0.5s, ease);
      color: rgba(112, 112, 112, 0.17);
      &:before {
        display: none;
      }
      &:hover {
        border-color: #FFEEE2;
        box-shadow: 0px 2px 12px rgba(255, 233, 209, 0.75);
        color: var(--main-color-one);
      }
    }
    .single-service {
      margin: 10px 15px 10px;
    }
    .single-service:hover {
      margin-top: 0;
    }
    &.slick-slider .slick-active .single-service {
      margin: 10px 15px 10px 7px;
    }
    &.slick-slider .slick-active ~ .slick-active .single-service {
      margin: 10px 15px 10px 15px !important;
    }
    &.slick-slider .slick-active ~ .slick-active ~ .slick-active .single-service {
      margin: 10px 15px 10px 15px !important;
    }
    &.slick-slider .slick-active ~ .slick-active ~ .slick-active ~ .slick-active .single-service {
      margin: 10px 7px 10px 15px !important;
    }
  }
  .readeal-top{
    overflow-wrap: break-spaces;
  }
}