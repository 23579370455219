.explore-area {



  @media (max-width: 580px) {
    padding-top: 50px;
    .section-title{
      border-bottom: 1px solid #dedede;
    }

    .single-explore{

    }

  }
}