.client-area{


  @media (max-width: 580px) {
    padding-top: 20px!important;
    padding-bottom: 30px;

    .section-title{
      border-bottom: 1px solid #dedede;
      margin-bottom: 1em;

    }
    .pd-top-85{

    }


  }
}