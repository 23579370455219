
.btn-bg{
  background: #fda94f;

}


h2 {

  font-size: 36px;
  margin-bottom: 15px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.5);
  margin-bottom: 25px;
  margin-top: 0px;

}

.flex-verticle-center {
  align-items: center;
}

.profile_container {
  display: flex;
  overflow-x: hidden;
  justify-content: center;
}

.agent-profile {
  .profile-img {
    width: 140px;
    border-radius: 50%;
  }
  .change-profile-overlay {
    background: var(--main-color-one);
    width: 100%;
    padding: .2em;
    color: white;
    cursor: pointer;
    text-align: center;
  }

}

.input-profile-pic {
  display: none;
}

.info {

  max-width: 900px;
  display: flex;
  overflow-x: hidden;
  margin-top: 20px;
}

.add-new-property-area li {
  padding: 8px 5px;
}

.back {
  margin: 60px 100px 0px;
  max-width: 900px;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  color: rgba(34, 34, 34, 0.5);
  i {
    margin-right: 15px;
  }
}

.profile_img-LG {
  height: 400px;
  width: 400px;
  border-radius: 40px;
  object-fit: cover;
  object-position: 50% 50%;
  background-position: 40% 50%;
}

.flag_wrapper {
  width: 50px;
  height: 50px;
  background-color: #f2f2f2;
  border-radius: 100%;
  position: relative;
  top: -70px;
  left: 230px;
}

.flag {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}

.description {
  margin-bottom: 30px;
  margin-top: 0;
}

.profile_img_section {
  margin-right: 50px;
}

.profile_desc_section {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  h2 {
    margin-bottom: 0;
    line-height: 35px;
    font-size: 25px;
  }
  .username {
    font-style: italic;
    color: #9d9d9d;
    margin-bottom: 1em;
  }
}

.interests_item {
  display: inline-block;
  padding: 0 10px;
  margin-right: 7.5px;
  line-height: 30px;
  background-color: #e6e6e6;
  border-radius: 5px;
  color: rgba(34, 34, 34, 0.5);
}

.link_img_wrapper {
  width: 45px;
  height: 45px;
  background-color: var(--main-color-one);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.link_img_wrapper:hover{

  color: var(--main-color-one);
  background-color:#fffa;


}

.link_img_wrapper p :hover{
  font-size: 20px;
}
.link_img {

  font-size: 18px;
}

ul {
  padding: 0px;
}

li {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-left: 10px;
    color: var(--paragraph-color);
  }
}


.padding {
  padding: 3rem !important
}

.user-card-full {
  overflow: hidden
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
  //background: -webkit-gradient(linear, left top, right top, from(#f7952c), to(#b53f0673));
  //background: linear-gradient(to right, #b53f0673, #f7952c)
  background: #ededed;
}

.user-profile {
  padding: 20px 0
}

.card-block {
  padding: 1.25rem
}

.m-b-25 {
  margin-bottom: 25px
}

.img-radius {
  border-radius: 5px
}

h6 {
  font-size: 14px
}

.card .card-block p {
  line-height: 32px
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px
  }
}

.card-block {
  padding: 1.25rem
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
  margin-bottom: 20px
}

.p-b-5 {
  padding-bottom: 5px !important
}

.card .card-block p {
  line-height: 30px
}

.m-b-10 {
  margin-bottom: 0px;
}

.text-muted {
  color: #919aa3 !important;
  font-size: 1em;
}

.b-b-default {
  border-bottom: 1px solid #ededed;
}

.f-w-600 {
  font-weight: 600;
  font-size: 1em;
}

.m-b-20 {
  margin-bottom: 20px
}

.m-t-40 {
  margin-top: 20px
}

.p-b-5 {
  padding-bottom: 5px !important
}

.m-b-10 {
  margin-bottom: 0px
}

.m-t-40 {
  margin-top: 20px
}

.user-card-full .social-link li {
  display: inline-block
}

.user-card-full .social-link li a {
  font-size: 25px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out
}
.profile-img{
  width: 150px ;
  box-shadow: 0px 2px 18px 10px;
}


.social-link li:hover{
  background: none;
}



@media screen and (max-width: 1000px) {
  .profile_container {
    margin: 10px 33px 0;
    flex-direction: column;
  }
  .info {
    //margin: 60px 33px 0px;
    margin-top: 10px;
    //margin-left: 33px;
  }
  .back {
    margin: 10px 33px 0px;
  }
  .profile_img_section {
    margin: 0 auto;
  }
  .profile_img-LG {
    width: 200px;
    height: 200px;
    border-radius: 100%;

  }
  .profile_desc_section {
    margin-left: 0px;
    margin-bottom: 10px;

  }
  .padding{
    padding: 0!important;
  }


}

.notification-ui {
  a {
    &:after {
      display: none;
    }
  }
}

.notification-ui_icon {
  position: relative;
  .unread-notification {
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 7px;
    background-color: var(--main-color-one);
    position: absolute;
    top: 15px;
    left: 12px;
  }
}

.notification-ui_dd {
  padding: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  border: 0;
  max-width: 400px;
  &:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - 7px);
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
  }
  .notification-ui_dd-header {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    h3 {
      margin-bottom: 0;
    }
  }
  .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;

  }
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 0;
  margin: 0 25px;
  .notification-list_img {
    img {
      height: 48px;
      width: 48px;
      border-radius: 50px;
      margin-right: 20px;
    }
  }
  .notification-list_detail {
    p {
      margin-bottom: 5px;
      line-height: 1.2;
      font-size: 15px;
      small {
        font-style: italic;
        font-size: 12px;
      }
    }
    hr {
      margin: .3em 0 !important;
    }
    .notification-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-container {
        button {
          line-height: initial !important;
          height: initial !important;
          padding: .5em;
          border-radius: 3px;
        }
      }

    }
  }
  .notification-list_feature-img {
    img {
      height: 48px;
      width: 48px;
      border-radius: 5px;
      margin-left: 20px;
    }
  }
}

.notification-list--unread {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -25px;
    height: calc(100% + 1px);
    border-left: 2px solid #29B6F6;
  }
}



.active-chat {
  background: #fd7e1457;
  color: #ffff !important;
  border: 1px solid #fd7e1485;
  transition:0.5s;
}

@media (max-width: 765px) {
  .profile_container{
    width: 32%;
  }
  .agentprofile-style{
    text-align: -webkit-center;
  }
  .user-profile{
    position: relative;
    width: 100%;

  }
  .padding{
    padding: 0 !important;
  }
  .profile-img{
    width: 37%;
  }
  .profile-btn{

    display: inline-block!important;
    margin: 0 5px!important;

        }

  .btn-bg{
    margin-left: 15px!important;
    margin-right: 15px!important;

      padding: 0 10px!important;
    font-size: 17px!important;

  }

}

@media (max-width: 999px) and (min-width: 765px) {
  .profile-btn{
    display: block!important;
  }
  .btn-bg{
    padding: 0 0px!important;
    margin-right: 5px!important;
    margin-left: 5px!important;
    font-size: 17px!important;
  }
}

@media (max-width: 999px) and (min-width: 765px) {
  .col-md-6,
  .col-lg-6{
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .profile-btn{
    display: flex!important;
    flex-direction: column!important;
    margin:0 16px;
    //justify-content: space-evenly!important;
  }
  .btn-bg{
    margin: 10px 0;
  }
}
@media (max-width: 400px){
  .user-profile{
    position: relative;
    width: 100%;

  }
  .card-block{
    position: relative;
    width: 100%;
  }
  .padding{
    padding: 0!important;
  }
  .profile-img{
    width: 35%;
  }

  .profile-btn{
    display: flex!important;
  }
  .btn-bg{

    padding: 0 4px!important;
    margin :0 5px !important;

    font-size: 13px!important;
  }

}


@media screen and (max-width: 568px)   {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 350px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -180px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -90px);
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
  }
}


@media screen and (max-width: 568px)   {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 350px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -165px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -80px);
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
  }
}

@media (min-width: 900px) {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 400px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -115px;
  }
}
@media (min-width: 768px) {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 400px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -190px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -73px);
    border: 15px solid transparent;
    border-bottom-color: #fff;
  }
}
@media (min-width: 650px) {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 400px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -190px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -73px);
    border: 15px solid transparent;
    border-bottom-color: #fff;
  }
}
@media (min-width: 580px) {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 400px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -190px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -73px);
    border: 15px solid transparent;
    border-bottom-color: #fff;
  }
}
@media (min-width: 570px) {
  .notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    width: 400px;
  }
  .dropdown-menu.show {
    display: block;
    margin-left: -190px;
  }
  .notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(50% - -73px);
    border: 15px solid transparent;
    border-bottom-color: #fff;
  }
}