.property-list-container {
  .price-range-container {
    display: flex;
    background: lightgoldenrodyellow;
    justify-content: space-between;
    align-items: center;
  }
  .search-page-wrap {
    .left-icon-location {
      &:before {
        top: 36px !important;
      }
    }
  }

  @media (max-width: 580px) {

    .price-range-container {
      display: block;
    }
    .price-range-container .widget-sidebar-item-wrap {
      margin-bottom: 30px !important;
    }

  }

  .overlay-sub-category-name {
    background: white;
    position: absolute;
    z-index: 1;
    padding: 0 2em;
    top: 1em;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-weight: 400;
  }

}

