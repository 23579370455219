/*
Theme Name: Realdeal
Theme URI: http://codingeek.net/html/realdeal
Author: Codingeek
Author URI: https://codingeek.net 
Description: RealDeal - Real Estate HTML Template
Version: 1.0.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Global
    ## Global
    ## Mixins
    ## Theme
    ## Variables
    ## Common

# Sections
    ## Nav Bar
    ## Banner
    ## Brand Area
    ## Footer
    ## Widgets

# Elements
    ## accordion
    ## filter
    ## apartment
    ## blog
    ## city
    ## feature
    ## gallery
    ## intro
    ## main-search
    ## pricing
    ## service
    ## team
    ## user-list

--------------------------------------------------------------*/
//== Global Design
@import "global/_mixins";
@import "global/_variables";
@import "global/_global";
@import "global/_theme";
@import "global/_common";
//== Pages
//== Sections 
@import "sections/_widgets";
@import "sections/_navbar";
@import "sections/_banner";
@import "sections/_sub-category";
@import "sections/_footer";
@import "sections/_section";
@import "sections/register";
@import "sections/agentprofile";
@import "sections/compnyprofile";
@import "sections/otp-screen";
@import "sections/private";
@import "sections/profile";
@import "sections/data-not-found";
@import "sections/jawahar-dodani-area";
@import "sections/explorer-best-agent";
@import "sections/client-area";
@import "sections/call-to-action-area";
@import "sections/single-user-list";
@import "sections/property-list";
@import "sections/why-choose-us-area";
@import "sections/user-list-area";
@import "sections/property-list-container";
@import "sections/propety-img-section";
@import "sections/agent-property-area";
@import "sections/favorite-property-list-section";
@import "sections/featured-area-section";
@import "sections/agent-profile-section";
@import "sections/testimonials-section";


//== Elements
@import "elements/_main_search";
@import "elements/_service";
@import "elements/_feature";
@import "elements/_intro";
@import "elements/_city";
@import "elements/_team";
@import "elements/_client";
@import "elements/_apartments_slider";
@import "elements/_gallery";
@import "elements/_all_filter";
@import "elements/_blog";
@import "elements/_accordion";
@import "elements/_comments";
@import "elements/_user_list";
@import "elements/_pricing";
@import "elements/search_grid";
@import "elements/_property";


.contact-area{

    .react-tel-input .form-control{
        width: 100% !important;
    }
}