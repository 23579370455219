/*--------------------------------------------------------------
	## Widgets
--------------------------------------------------------------*/
.sitebar {
	.widget {
		margin-bottom: 50px;
	}
}
.widget {
	border: 1px solid rgba(197, 197, 197, 0.5);
    border-radius: 8px;
}
.widget-title {
	font-weight: 700;
	margin-bottom: 16px;
}
.widget_nav_menu {
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			a {
				color: rgba(0, 0, 0, 0.80);
				font-size: 15px;
				@include transition(all, 0.4s, ease);
				&:hover {
					color: var(--main-color-one);
				}
			}
		}
	}
}
.widget-subscribe {
	.rld-single-input {
		margin-bottom: 10px;
	}
}


.widget-sidebar-search-wrap {
	padding: 30px;
	.btn-wrap .btn {
		margin-top: 40px;
	}
}
.widget-sidebar-search {

  .widget-sidebar-item-wrap input{
    padding: 0 15px !important;
  }
	float: left;
	.title {
		font-size: 18px;
		font-weight: 600;
		font-family: var(--heading-font);
		color: var(--heading-color);
		margin-bottom: 15px;
		line-height: 1;
	}
	.price {
		line-height: 1;
		padding-top: 5px;
	}
	.btn {
		height: 40px;
		line-height: 40px;
	    padding: 0 18px;
	}
	.widget-sidebar-item-wrap {
		margin-bottom: 30px;
	    display: block;
	    float: left;
	    width: 100%;
	}
	.rld-single-input.left-icon input,
	.rld-single-select .single-select {
		height: 40px;
		line-height: 40px;

	}
	.rld-single-input.left-icon:before {
	    top: 14px;
	    left: 17px;
	    background-size: contain;
		height: 13px;
		width: 13px;
	}
	.rld-single-select .single-select:before {
	    top: 16px;
        height: 9px;
	    width: 9px;
	    background-size: 100%;
	}
  .rld-single-input :focus, .rld-single-input :active{
        border-color: #fda94f;
  }



  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  padding: 30px;
  .btn-wrap .btn {
    margin-top: 40px;
  }

  input:checked + .slider {
    background-color: #fda94f;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #fda94f;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }





}

/********* widget-owner-info ********/ 
.widget-owner-info {
	.owner-info {
		border-bottom: 1px solid rgba(197, 197, 197, 0.5);
		padding: 30px 15px 26px 15px;
		.thumb {
			border: 1px solid rgba(197, 197, 197, 0.5);
    		border-radius: 8px;
    		display: inline-block;
    		padding: 10px;
    		margin-bottom: 15px;
    		img {
    			border-radius: 6px;
    		}
		}
		h6 {
			margin-bottom: 0;
		}
		.designation {
			font-size: 12px;
		}
		p {
			margin-bottom: 0;
			color: var(--main-color-one);
			span {
				color: var(--heading-color);
				margin: 0 6px;
			}
		}
	}
	.contact {
		padding: 27px 30px 30px;
		border-bottom: 1px solid rgba(197, 197, 197, 0.5);
		.rld-single-input {
			margin-bottom: 20px;
			input {
				border: 0;
				border-radius: 0;
				border-bottom: 1px solid rgba(197, 197, 197, 0.5);
				padding: 3px 0;
				height: 40px;
				line-height: 40px;
				font-size: 14px !important;
				background: #F6F7FB;
			}
		}
	}
	.contact-info {
		padding: 27px 30px 20px;
		.media {
			margin-bottom: 15px;
			.media-left {
				margin-right: 10px;
				i {
					color: var(--main-color-one);
				}
			}
			.media-body {
				p {
					font-size: 14px;
					font-weight: 500;
					font-family: var(--heading-font);
					color: var(--heading-color);
					margin-bottom: 0;
				}
				span {
					font-size: 15px;
				}
			}
		}
	}
}