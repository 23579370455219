.property-container {
  .sub-category-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    .sub-category {
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 21%;
      border: 1px solid #d4d4d4;
      max-width: 350px;
      border-radius: 1.5em;
    }

  }
  #country-select {
    box-shadow: 1px 0px 3px 0px;
    position: absolute;
    width: 100%;
    height: auto;
    border: 1px solid #d4d4d4;
    z-index: 1111111111111;
    background: #ffff;
    font-family: inherit;
    color: initial;
    font-size: inherit;
    display: none;

  }
  #country-select li{

    padding-left: 20px;
    cursor: pointer;

  }
  #country-select  li:hover{
    background: #007bff;

    color: white;
  }
  #state-select {
    box-shadow: 1px 0px 3px 0px;
    position: absolute;
    width: 100%;
    height: auto;
    border: 1px solid #d4d4d4;
    z-index: 1111111111111;
    background: #ffff;
    font-family: inherit;
    color: initial;
    font-size: inherit;
    display: none;

  }
  #state-select li{
    font-size: 1.2em;
    padding-left: 20px;
    cursor: pointer;

  }
  #state-select  li:hover{
    background: #007bff;

    color: white;
  }
  #city-select {
    box-shadow: 1px 0px 3px 0px;
    position: absolute;
    width: 100%;
    height: auto;
    border: 1px solid #d4d4d4;
    z-index: 1111111111111;
    background: #ffff;
    font-family: inherit;
    color: initial;
    font-size: inherit;
    display: none;

  }
  #city-select li{

    padding-left: 20px;
    cursor: pointer;

  }
  #city-select  li:hover{
    background: #007bff;

    color: white;
  }
  @media (max-width: 555px) {
    .sub-category {
      flex: 1 0 100% !important;
      border: none !important;
    }
    .rld-single-input input{
      padding: 0 15px;
      font-size: 14px;
    }
  }
  @media (max-width: 1000px) and (min-width: 555px){

    .sub-category {
      flex: 1 0 44% !important;
    }

  }
  @media (max-width: 320px) {
    .contact-form-wrap{
      padding: 13px;
    }
    .col-12{
      padding: 0px !important;


    }
    .rld-single-input input{
      //padding: 0 10px;
      //font-size: 11px !important;
    }
    .lbl{
      text-align: center !important;
    }
  }
  @media (max-width: 756px) {
    .lbl{
      text-align: center !important;
    }
  }

  .rld-single-input {
    input, textarea {
      background: white !important;
    }
  }
  .rld-single-select .single-select{

  }

  .upload-property-img{
    width: 100% !important;
    margin: 0 !important;
    border-radius: 10px !important;
  }

  .amenities-container {
    display: flex;
    flex-wrap: wrap;
    .amenity {
      border: 1px solid #ededed;
      margin: 1em;
      padding: .5em 1em;
      width: 250px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        flex-basis: 20px;
        padding: .5em;
      }
      span {
        margin-left: 1em;
        text-align: left;
      }
    }
    .active-amenity {
      background: #fda94f85;
      border: 1px solid var(--main-color-one);
    }
  }

}