.companyprofile-container {
  position: inherit;
  img {

    width: 50%;
    margin-bottom: 2em;
    border-radius: 15px;

  }
  i {
    position: absolute;
    font-size: 18px;
    bottom: 14%;
    left: 73%;
    background: white;
    box-shadow: 6px 9px 14px 7px #ededed;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
  }
  input[type ="file"] {
    display: none;
  }
  
  @media (max-width: 789px ) {
     i{
       bottom: 16%;
       left: 71%;
     }

  }
  @media (max-width: 291px) {
    img{
      width: 70%;
    }
    i{
      bottom: 20%;
      left: 78%;
      font-size: 0.8em;
    }
  }
  @media (min-width: 291px) and (max-width: 499px) {
    img{
      width: 70%;
    }
    i{
      bottom: 18%;
      left: 81%;
      font-size: 0.8em;
    }
  }

}