.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.jzZPoY {
    font-family: sans-serif;
    display: grid;
    grid-template-columns: 20px 100px auto 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto !important;
    color: #000;
    background: #f2f2f2;
    box-shadow: 0 1px 2px rgb(0 0 0 / 80%) inset, 0 1px 1px rgb(255 255 255 / 30%);
    padding: 10px;
    border-radius: 0;
}