.testimonials-section{


  .upload-testimonial-img-section{
    width: 50%;

  }
//      .dropdown .Drop-menu{
//  //position: absolute;
//  //inset: 0px auto auto -  132px !important;
//  transform: translate(255.444px, 38.8889px)!important;
//  //float: left;
//}
//  @media (max-width: 765px) {
//    .dropdown .Drop-menu {
//      //position: absolute;
//      transform: translate(338.444px, 38.8889px) !important;
//      //transform: translate(255.444px, 38.8889px)!important;
//      //inset: 0px auto auto 0px !important;
//    }
//  }
//  @media (min-width: 999px) and (max-width: 1400px) {
//    .dropdown .Drop-menu {
//      transform: translate(184.444px, 38.8889px) !important;
//    }
//  }
}