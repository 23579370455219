/*--------------------------------------------------------------
	## Comments
--------------------------------------------------------------*/
.comment-content a {
	word-wrap: break-word;
}

.bypostauthor {
	display: block;
}

.comments-area {
	margin-top: 65px;
}

.comments-area .comments-title {
	line-height: 33px;
	margin-bottom: 40px;
	font-family: var(--body-font);
}
.comments-area .comments-title span {
	font-size: 18px;
	line-height: 24px;
	// color: $gray;
}
.comments-area .comment-list .comment-respond {
	margin-bottom: 30px;
}
.comments-area .comment-list {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style-type: none;
	margin-bottom: 40px;
}

.comments-area .comment-list li {
	margin-top: 30px;
}

.comments-area .comment-list li:last-child {
	margin-bottom: 0;
}

.comments-area .comment-list li:last-child .single-comment-wrap {
	border: 0;
	padding-bottom: 0;
}

.comments-area .comment-list li ul.children {
	margin: 0;
	padding: 0;
	margin-left: 50px;
	list-style: none;
	list-style-type: none;
}
.comments-area .comment-list li ul{
	list-style: none;
}
.comments-area .comment-list li ul.children {
	margin-left: 50px;
}
.comments-area .comment-list li ul.children li {
	list-style-type: none;
	list-style: none;
}

.comments-area .comment-list li .single-comment-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: start;
	align-self: flex-start;
	border-bottom: 1px solid rgba(112, 112, 112, 0.2);
	padding-bottom: 20px;
}

.comments-area .comment-list li .single-comment-wrap .thumb {
	margin-right: 25px;
	height: 65px;
	width: 65px;
	border-radius: 8px;
}
.comments-area .comment-list li .single-comment-wrap .thumb img {
	border-radius: 8px;
}
.comments-area .comment-list li .single-comment-wrap .date, 
.blog-details-page-content-area .comments-area .comment-list li .single-comment-wrap .time {
	display: block;
	font-size: 13px;
	line-height: 17px;
	letter-spacing: 0.14px;
	margin-bottom: 18px;
	color: rgba(66, 84, 134, 0.8);
}

.comments-area .comment-list li .single-comment-wrap .content {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
	overflow: auto;
}

.comments-area .comment-list li .single-comment-wrap .content .title {
	font-size: 16px;
	line-height: 27px;
	font-weight: 600;
	letter-spacing: 0.22px;
	margin-bottom: 5px;
	font-family: var(--body-font);
}
.comments-area .comment-list li .single-comment-wrap .content .comment-content {
	max-width: 620px;
}
.comments-area .comment-list li .single-comment-wrap .content p {
	font-size: var(--body-font-size);
	line-height: var(--line-height30);
	letter-spacing: 0.3px;
	margin-bottom: 15px;
	// color: $gray;
	word-break: break-word;
}

.comments-area .comment-list li .single-comment-wrap .content p:last-child {
	margin-bottom: 0;
}

.comments-area .comment-list li .single-comment-wrap .content .like {
	letter-spacing: 0.3px;
	transition: 0.4s;
	height: 35px;
	line-height: 35px;
	padding: 0 10px;
	font-weight: 600;
	color: var(--heading-color);
	i {
		font-weight: 700;
		margin-right: 4px;
	}
}

.comments-area .comment-list li .single-comment-wrap .content .reply {
	letter-spacing: 0.3px;
	transition: 0.4s;
	height: 35px;
	line-height: 35px;
	padding: 0 10px;
	font-weight: 600;
	color: var(--heading-color);
	span {
	    padding-left: 25px;
	    padding-right: 0;
	}
}

.comments-area .comment-list li .single-comment-wrap .content .reply:hover {
	// color: $dark-blue;
}


.comments-area .reply-title,
.comments-area .comment-reply-title{
	font-size: 26px;
	line-height: 34px;
	font-weight: 600;
	margin-bottom: 20px;
}
.comments-area .form-submit{
	margin-bottom: 0;
}
.comment-form .form-group.textarea .form-control {
	min-height: 160px;
	resize: none;
	line-height: 26px;
}
.comment-form .form-group.textarea .form-control:focus{
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.comment-form .form-group .form-control {
	height: 50px;
	line-height: 50px;
	border: 1px solid #e2e2e2;
}

.comment-form .submit-btn {
	height: 50px;
	width: auto;
	border-radius: 5px;
	font-weight: 400;
	background-color:var(--main-color-one);
	border: none;
	cursor: pointer;
	transition: .3s ease-in;
	color: $color-white;
}
.comment-form .submit-btn:hover {
	background-color: var(--secondary-color);
	color: #fff;
}
.comment-respond {
	/*margin-top: 20px;*/
}

.comments-area .comment-list .children li:last-child {
	margin-bottom: 30px;
}
.comment-form-cookies-consent input[type=checkbox] {
	margin-right: 5px;
}